import React from "react";
import {
  storyblokEditable,
  StoryblokComponent
} from "@storyblok/react";
import IconBox from "@pagepro-monorepo/ui/lib/components/molecules/IconBox";
import { Box } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import LazyHydrate from "@components/utils/LazyHydrate";
import RichText from "@components/atoms/RichText";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { IconBoxStoryblokProps } from "./types";

const IconBoxStoryblok: React.FC<IconBoxStoryblokProps> = ({
  blok
}) => {
  const { title, description, icon, titleColor, block } = blok;

  return (
    <>
      <LazyHydrate
        useDisplayContents={false}
        noWrapper
        whenVisible
        getEventTarget={() => window}
      >
        <IconBox
          title={<RichText content={title[0]?.text} />}
          description={
            description?.[0] ? (
              <RichText content={description[0].text} />
            ) : undefined
          }
          icon={tryToReplaceDomain(icon.filename)}
          titleColor={titleColor?.color}
          {...storyblokEditable(blok)}
        />
      </LazyHydrate>
      {block?.length ? (
        <Box mt="0.75rem">
          {block.map((item) => (
            <StoryblokComponent key={item._uid} blok={item} />
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default IconBoxStoryblok;
